exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-activate-online-tsx": () => import("./../../../src/pages/activate-online.tsx" /* webpackChunkName: "component---src-pages-activate-online-tsx" */),
  "component---src-pages-activate-tsx": () => import("./../../../src/pages/activate.tsx" /* webpackChunkName: "component---src-pages-activate-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-coverage-tsx": () => import("./../../../src/pages/coverage.tsx" /* webpackChunkName: "component---src-pages-coverage-tsx" */),
  "component---src-pages-crtc-js": () => import("./../../../src/pages/crtc.js" /* webpackChunkName: "component---src-pages-crtc-js" */),
  "component---src-pages-help-contentful-faq-category-page-slug-tsx": () => import("./../../../src/pages/help/{ContentfulFaqCategory.pageSlug}.tsx" /* webpackChunkName: "component---src-pages-help-contentful-faq-category-page-slug-tsx" */),
  "component---src-pages-help-index-tsx": () => import("./../../../src/pages/help/index.tsx" /* webpackChunkName: "component---src-pages-help-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-plans-tsx": () => import("./../../../src/pages/plans.tsx" /* webpackChunkName: "component---src-pages-plans-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-top-up-tsx": () => import("./../../../src/pages/top-up.tsx" /* webpackChunkName: "component---src-pages-top-up-tsx" */),
  "component---src-pages-website-terms-conditions-js": () => import("./../../../src/pages/website-terms-conditions.js" /* webpackChunkName: "component---src-pages-website-terms-conditions-js" */),
  "component---src-templates-campaign-index-js": () => import("./../../../src/templates/campaign/index.js" /* webpackChunkName: "component---src-templates-campaign-index-js" */),
  "component---src-templates-faq-category-answers-faq-category-answers-tsx": () => import("./../../../src/templates/FaqCategoryAnswers/FaqCategoryAnswers.tsx" /* webpackChunkName: "component---src-templates-faq-category-answers-faq-category-answers-tsx" */),
  "component---src-templates-redirect-index-js": () => import("./../../../src/templates/redirect/index.js" /* webpackChunkName: "component---src-templates-redirect-index-js" */)
}

